.gallery{
  min-height: 195px;
  margin-bottom: 1em;
  background-color: $clr-light;
  border: 1px solid $clr-detail-light;

  &:last-of-type{
    margin-bottom: 0;
  }

  &__information{
    margin: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__information::after{
    background-color: $clr-dark;
    position: absolute;
    display: block;
    bottom: -0.5em;
    width: 100%;
    height: 1px;
    content: "";
  }

  &__information > h2{
    text-transform: uppercase;
    color: $clr-dark;
    margin: 0;
  }

  &__photoframe{
    grid-template-columns: repeat(auto-fill, 125px);
    justify-content: center;
    display: grid;
    gap: 0.5em;
  }

  &__photoframe > figure{
    cursor: pointer;
  }

  &__photoframe > figure > img{
    object-fit: cover;
    display: block;
    height: auto;
    width: 100%;
  }

  &__photoframe > figure > figcaption{
    text-align: center;
    font-style: normal;
    overflow: hidden;
    font-weight: 600;
    padding: 0.4em 0;
    font-size: 1rem;
  }
}