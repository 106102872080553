// Third-party libraries and packages.
@import '../../node_modules/normalize.css/normalize.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

// Project specific styles.
@import './project/_project';
@import './components/_components';
@import './pages/_pages';

// Element styles.
body{
  margin: 0;
  padding: 4em 0;
  font-family: 'Roboto', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $clr-body-background;
}

.page-layout{
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  background-color: $clr-light-page;
  border-radius: 10px;
  max-width: 1360px;
  padding: 4em 2em;
  margin: 0 auto;
}

.main-header,
.page-layout__logo{
  text-align: center;
}

// Responsiv styles.
@import './responsiv/responsiv';