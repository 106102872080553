$clr-dark: #222222;
$clr-light: #FFFFFF;

$clr-light-box: #E8E8E8;
$clr-light-page: #EEEEEE;

$clr-detail-dark: #679111;
$clr-detail-light: #D3EA80;

$clr-active-link: rgba(255,255,255,0.8);
$clr-body-background: rgba(226, 185, 126, 0.2);