@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
h1 {
  font-size: 2.2rem;
  line-height: 1em;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.5px; }

h2, h3, h4, h5, h6 {
  font-size: 2rem;
  line-height: 1em;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.5px; }

p {
  font-size: 1.8rem;
  line-height: 1.2em;
  font-weight: normal;
  font-style: normal;
  color: #222222; }

a {
  font-size: 1.6rem;
  color: #D3EA80;
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.5px; }

time {
  font-size: 1.6rem;
  font-weight: normal;
  font-style: italic;
  text-transform: capitalize;
  color: #222222;
  font-size: 1.2rem; }

figcaption {
  color: #222222; }

strong {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-style: normal;
  font-weight: bold;
  line-height: 1em;
  font-size: 1.5rem; }

small {
  color: #222222;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9rem; }

:root {
  font-size: 10px; }

*,
*::before,
*::after {
  padding: 0;
  box-sizing: border-box; }

::-moz-focus-inner {
  border: 0; }

figure {
  margin: 0; }

a:link,
a:hover,
a:visited,
a:active {
  text-decoration: none; }

ul {
  list-style: none; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.display-560px,
.display-760px {
  display: none; }

.main-navigation, .lightbox-section {
  display: flex;
  justify-content: center;
  align-items: center; }

.page-banner {
  background-color: #222222;
  color: #FFFFFF; }
  .page-banner--expanded > img {
    object-fit: cover;
    width: 100%;
    height: auto;
    display: block; }
  .page-banner--bar {
    height: 100px;
    display: flex;
    padding: 0 2em;
    align-items: center; }

.main-navigation {
  margin-top: 1em;
  height: 80px; }
  .main-navigation > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    width: 100%;
    margin: 0; }

.main-navigation__link > a {
  font-size: 2rem;
  color: #222222;
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
  position: relative; }

.main-navigation__link > a.active::after {
  content: "";
  display: block;
  height: 4px;
  background-color: #679111;
  position: absolute;
  width: 100%;
  bottom: -6px; }

.main-footer {
  justify-content: space-between;
  background-color: #222222;
  padding: 1em 10em 0;
  flex-wrap: wrap;
  display: flex; }
  .main-footer__column {
    flex: 1 1 370px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    height: 140px;
    display: flex; }
  .main-footer > div > strong {
    text-transform: uppercase;
    color: #679111;
    letter-spacing: 0.5px;
    font-style: normal;
    font-weight: bold;
    line-height: 1em;
    font-size: 2rem; }
  .main-footer li {
    font-weight: normal;
    font-style: normal;
    line-height: 1.2em;
    color: #FFFFFF;
    font-size: 2rem; }
  .main-footer a:hover {
    text-decoration: underline; }

.lightbox-section {
  background-color: #222222;
  position: fixed;
  z-index: 50;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0; }

.lightboxdiv {
  width: 100%;
  padding: 0 1em;
  max-width: 800px;
  border-radius: 10px;
  background-color: #EEEEEE;
  flex-direction: column;
  position: relative;
  display: flex; }
  .lightboxdiv__arrow-container {
    justify-content: space-between;
    align-items: center;
    display: flex; }
  .lightboxdiv__arrow-container > .arrow {
    border: solid #679111;
    border-width: 0 10px 10px 0;
    display: inline-block;
    position: absolute;
    padding: 0.5em; }
  .lightboxdiv__arrow-container > .arrow:hover,
  .lightboxdiv__arrow-container > .arrow:focus {
    border-width: 0 10px 10px 0;
    cursor: pointer;
    opacity: 0.8; }
  .lightboxdiv__arrow-container > .prev-arrow {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    left: -25px;
    top: 50%; }
  .lightboxdiv__arrow-container > .next-arrow {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    right: -25px;
    top: 50%; }
  .lightboxdiv__gallery-header {
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 5vh; }
  .lightboxdiv__gallery-header > .close-button {
    border: 2px solid #D3EA80;
    background-color: #222222;
    border-radius: 50%;
    color: #FFFFFF;
    padding: 6px 8px; }
  .lightboxdiv__gallery-header > .close-button:hover,
  .lightboxdiv__gallery-header > .close-button:focus {
    border: 2px solid #679111;
    cursor: pointer;
    outline: none; }
  .lightboxdiv__gallery-body > img {
    background-color: #222222;
    object-fit: contain;
    max-height: 75vh;
    width: 100%; }
  .lightboxdiv__gallery-body > figcaption {
    letter-spacing: 0.5px;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    line-height: 1em;
    font-size: 1.2rem;
    height: 5vh;
    display: flex;
    justify-content: space-between; }

.option-box {
  display: flex;
  flex-direction: column; }
  .option-box > label {
    font-size: 1.5rem;
    line-height: 2em;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.5px; }

.filter-select {
  outline: none;
  height: 38px;
  padding-left: 10px;
  border: 1px solid #222222;
  font-size: 1.4rem; }
  .filter-select:hover, .filter-select:focus {
    border: 1px solid #679111; }
  .filter-select > option {
    display: flex;
    justify-content: space-between; }

.calendar {
  background-color: #EEEEEE;
  grid-template-columns: repeat(4, 80px);
  grid-template-rows: repeat(3, 80px);
  justify-content: center;
  margin-top: 4em;
  display: grid;
  grid-gap: 0.5em;
  gap: 0.5em; }
  .calendar__placeholder {
    padding: 0.5em;
    border: 1px solid #222222;
    justify-content: space-between;
    background-color: #EEEEEE;
    flex-direction: column;
    display: flex; }
  .calendar__placeholder > strong {
    text-align: center; }
  .calendar__placeholder > .month {
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex: 1 1; }
  .calendar__placeholder > .month > .indicator {
    background-color: #679111; }

.home-content {
  padding: 2em 0; }
  .home-content__textbox {
    margin: 0 auto;
    max-width: 850px;
    padding-bottom: 1em; }
  .home-content__textbox > h1 {
    margin-bottom: 1.6em;
    color: #222222; }
  .home-content__placeholder {
    height: 500px;
    background-image: url(/static/media/hops_farmers.5d0c81f3.jpg);
    background-position: bottom center;
    background-size: cover;
    max-width: 1024px;
    margin: 2em auto; }
  .home-content__placeholder > .caption-text {
    font-size: 1.6rem;
    font-weight: normal;
    font-style: italic;
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    color: #FFFFFF;
    padding: 0.2em 0;
    display: block; }

.page-content {
  display: flex; }

@keyframes arrow {
  from {
    background-position-y: -2px; }
  to {
    background-position-y: -8px; } }

@-webkit-keyframes arrow {
  from {
    background-position-y: -2px; }
  to {
    background-position-y: -8px; } }

.content-box {
  padding: 0.5em;
  border: 1px solid #222222;
  background-color: #E8E8E8;
  margin: 8em 0 18em;
  min-height: 480px; }
  .content-box.main-content-box {
    flex: 0 1 950px; }
  .content-box.content-sidebar {
    margin-left: 2em;
    position: -webkit-sticky;
    position: sticky;
    min-width: 335px;
    height: 480px;
    top: 1em;
    flex: 1 1; }
  .content-box.content-sidebar > .scroll-top-icon {
    left: 50%;
    width: 140px;
    height: 140px;
    bottom: -160px;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-position-y: -2px;
    border: solid 2px #679111;
    background-color: rgba(226, 185, 126, 0.2);
    background-image: url(/static/media/angle-double-up-solid.bf9e9e47.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-name: arrow;
            animation-name: arrow; }

.gallery {
  min-height: 195px;
  margin-bottom: 1em;
  background-color: #FFFFFF;
  border: 1px solid #D3EA80; }
  .gallery:last-of-type {
    margin-bottom: 0; }
  .gallery__information {
    margin: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; }
  .gallery__information::after {
    background-color: #222222;
    position: absolute;
    display: block;
    bottom: -0.5em;
    width: 100%;
    height: 1px;
    content: ""; }
  .gallery__information > h2 {
    text-transform: uppercase;
    color: #222222;
    margin: 0; }
  .gallery__photoframe {
    grid-template-columns: repeat(auto-fill, 125px);
    justify-content: center;
    display: grid;
    grid-gap: 0.5em;
    gap: 0.5em; }
  .gallery__photoframe > figure {
    cursor: pointer; }
  .gallery__photoframe > figure > img {
    object-fit: cover;
    display: block;
    height: auto;
    width: 100%; }
  .gallery__photoframe > figure > figcaption {
    text-align: center;
    font-style: normal;
    overflow: hidden;
    font-weight: 600;
    padding: 0.4em 0;
    font-size: 1rem; }

.bloggpost {
  min-height: 195px;
  background-color: #FFFFFF;
  border: 1px solid #D3EA80;
  margin-bottom: 1em; }
  .bloggpost:last-of-type {
    margin-bottom: 0; }
  .bloggpost__information {
    justify-content: space-between;
    align-items: center;
    position: relative;
    display: flex;
    margin: 1.5em; }
  .bloggpost__information::after {
    background-color: #222222;
    position: absolute;
    bottom: -0.5em;
    display: block;
    width: 100%;
    height: 1px;
    content: ""; }
  .bloggpost__information > h2 {
    text-transform: uppercase;
    color: #222222;
    margin: 0; }
  .bloggpost__body {
    padding: 0 2em; }

.contact-form {
  margin: 4em; }
  .contact-form__group {
    display: flex;
    flex-direction: column;
    margin-top: 1rem; }
  .contact-form__group > label {
    padding: 0.5rem;
    color: #222222;
    font-size: 1.4rem; }
  .contact-form__group > input,
  .contact-form__group > textarea {
    padding-left: 1rem;
    border: 1px solid #222222;
    border-radius: 5px;
    font-size: 1.4rem;
    outline: none;
    height: 38px; }
  .contact-form__group > input:focus,
  .contact-form__group > textarea:focus {
    border: 1px solid #D3EA80; }
  .contact-form__group > textarea {
    padding: 1rem;
    height: 150px;
    resize: none; }
  .contact-form__button {
    background-color: #679111;
    margin: 2rem 0.5rem 0 auto;
    padding: 1rem 2rem;
    font-size: 1.5rem;
    color: #FFFFFF;
    font-weight: bold;
    display: block;
    outline: none; }
  .contact-form__button:hover {
    background-color: #222222;
    cursor: pointer; }

body {
  margin: 0;
  padding: 4em 0;
  font-family: 'Roboto', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: rgba(226, 185, 126, 0.2); }

.page-layout {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  background-color: #EEEEEE;
  border-radius: 10px;
  max-width: 1360px;
  padding: 4em 2em;
  margin: 0 auto; }

.main-header,
.page-layout__logo {
  text-align: center; }

@media only screen and (max-width: 1370px) {
  body {
    margin: 0;
    padding: 0; } }

@media only screen and (max-width: 1360px) {
  .page-layout {
    box-shadow: none;
    border-radius: 0;
    padding: 1em; } }

@media only screen and (max-width: 1060px) {
  .contact-form {
    margin: 1em; } }

@media only screen and (max-width: 960px) {
  .lightbox-section {
    align-items: flex-end; }
  .lightboxdiv {
    padding: 0 0.5em;
    border-radius: 0; }
    .lightboxdiv__arrow-container {
      height: 10vh;
      padding: 0 2em; }
    .lightboxdiv__arrow-container > .arrow {
      position: static; }
    .lightboxdiv__gallery-body > img {
      max-height: 65vh; }
    .lightboxdiv__gallery-body > figcaption {
      font-size: 1.4rem; } }

@media only screen and (max-width: 760px) {
  .page-banner--bar {
    height: 70px; }
  .page-layout {
    padding: 0.5em; }
  .contact-form {
    margin: 0.5em; }
  .page-content {
    display: block; }
  .display-760px {
    display: block; }
  .content-box {
    padding: 0.3em;
    min-height: auto;
    margin: 1em 0 3em;
    padding-bottom: 2em; }
    .content-box.content-sidebar {
      display: none; } }

@media only screen and (max-width: 560px) {
  :root {
    font-size: 7px; }
  .display-560px {
    display: block; }
  .page-layout__logo {
    display: none; }
  .photo-placeholder > img {
    max-height: 450px; }
  .photo-placeholder > figcaption {
    padding: 0.5em 0.5em 0.25em; }
  .mobile-menu-toggle-icon {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 50px;
    width: 50px; }
  .mobile-menu-toggle-icon > img {
    display: block;
    height: auto;
    width: 50%; }
  .main-navigation {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    background-color: #EEEEEE;
    -webkit-transition: -webkit-transform 0.15s linear 0s;
    transition: -webkit-transform 0.15s linear 0s;
    transition: transform 0.15s linear 0s;
    transition: transform 0.15s linear 0s, -webkit-transform 0.15s linear 0s;
    border: 1em solid rgba(226, 185, 126, 0.2);
    position: absolute;
    display: block;
    height: 100vh;
    margin-top: 0;
    padding: 2em;
    z-index: 50;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0; }
    .main-navigation.mobile-menu-open {
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    .main-navigation > ul > .mobile-menu-close-link {
      background-color: #222222;
      border-radius: 50%;
      color: #FFFFFF;
      padding: 2em 4em; }
    .main-navigation > ul > .mobile-menu-close-link:hover,
    .main-navigation > ul > .mobile-menu-close-link:focus {
      color: #D3EA80;
      cursor: pointer; }
    .main-navigation > ul {
      flex-direction: column;
      justify-content: space-evenly;
      margin-top: -40px;
      max-width: 100%;
      height: 100%; } }

