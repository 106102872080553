.lightbox-section{
  background-color: $clr-dark;
  @extend %flex-center;
  position: fixed;
  z-index: 50;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.lightboxdiv{
  width: 100%;
  padding: 0 1em;
  max-width: 800px;
  border-radius: 10px;
  background-color: $clr-light-page;
  flex-direction: column;
  position: relative;
  display: flex;

  &__arrow-container{
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  &__arrow-container > .arrow{
    border: solid $clr-detail-dark;
    border-width: 0 10px 10px 0;
    display: inline-block;
    position: absolute;
    padding: 0.5em;
  }

  &__arrow-container > .arrow:hover,
  &__arrow-container > .arrow:focus{
    border-width: 0 10px 10px 0;
    cursor: pointer;
    opacity: 0.8;
  }

  &__arrow-container > .prev-arrow{
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    left: -25px;
    top: 50%;
  }

  &__arrow-container > .next-arrow{
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    right: -25px;
    top: 50%;
  }

  &__gallery-header{
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 5vh;
  }

  &__gallery-header > .close-button{
    border: 2px solid $clr-detail-light;
    background-color: $clr-dark;
    border-radius: 50%;
    color: $clr-light;
    padding: 6px 8px;
  }

  &__gallery-header > .close-button:hover,
  &__gallery-header > .close-button:focus{
    border: 2px solid $clr-detail-dark;
    cursor: pointer;
    outline: none;
  }

  &__gallery-body > img{
    background-color: $clr-dark;
    object-fit: contain;
    max-height: 75vh;
    width: 100%;
  }

  &__gallery-body > figcaption{
    letter-spacing: 0.5px;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    line-height: 1em;
    font-size: 1.2rem;
    height: 5vh;
    display: flex;
    justify-content: space-between;
  }
}